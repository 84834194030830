.SkeletonImg {
  padding: 0 42px;

  @media only screen and (max-width: 575px) {
    padding: 0;
  }
}

.SkeletonText {
  width: 331px;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}