.ant-row-middle {
  align-items: baseline;
}

.ant-card-meta-title {
  white-space: unset;
}

.disabled-link {
  pointer-events: none;
}