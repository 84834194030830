@import "~antd/dist/antd.css";

html {
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f9;
}

body {
  margin: 0;
  font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 326px;
  overflow-x: hidden;
  max-width: 1600px;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
