.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ant-modal-footer {
  border-radius: 0 0 8px 8px;
}

.ant-input {
  border-radius: 8px;
}

.ant-input-password {
  border-radius: 8px;
}
