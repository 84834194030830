.CampaignPage {
  margin: 100px;
  margin-top: 50px;
}

@media only screen and (max-width: 800px) {
  .CampaignPage {
    margin: 20px;
    padding-bottom: 20px;
  }
}

.CampaignPageTitle {
  font-weight: bold;
  font-size: 1.6rem;
}

.CampaignPageDescription {
  font-family: "Noto Sans TC";
  font-weight: normal;
  font-size: 1.1rem;
  white-space: pre-wrap;
}

.CampaignPageLabel {
  color: gray;
  font-size: 1rem;
  line-height: 80px;
}
